<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-v-component
      heading="DMS Service"
      link="components/simple-tables"
    />
    <v-container>
      <v-layout wrap>
        <v-flex xs12>
          </v-flex>
        </v-layout>
      </v-container>    
    <v-row>
      <v-col cols="12">
        <template>
          <v-stepper v-model="wizard">
            <v-stepper-header>
              <v-stepper-step
                :complete="wizard > 1"
                step="1"
              >
                Select Date Range
              </v-stepper-step>

              <v-divider />
              <v-stepper-step
                :complete="wizard > 2"
                step="2"
              >
                Filter by Year, Make, Model, State
              </v-stepper-step>

              <v-divider />
              <v-stepper-step
                :complete="wizard > 3"
                step="3"
              >
                Select Columns
              </v-stepper-step>

              <v-divider />
              <v-stepper-step
                :complete="wizard > 4"
                step="4"
              >
                Select Campaign
              </v-stepper-step>

              <v-divider />
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-card
                  color="lighten-1"
                >
                  <v-row>
                    <v-col
                      class="xs"
                      sm="6"
                      md="4"
                      lg="2"
                      xl="1"
                    >
                      <v-menu
                        v-model="start_date_menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="formattedStartDate"
                            label="Start Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="start_date"
                          @input="start_date_menu = false"
                        />
                      </v-menu>
                    </v-col>
                    <v-col
                      class="xs"
                      sm="6"
                      md="4"
                      lg="2"
                      xl="1"
                    >
                      <v-menu
                        v-model="end_date_menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="formattedEndDate"
                            label="End Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="end_date"
                          @input="end_date_menu = false"
                        />
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-card>
                <v-btn
                  color="primary"
                  @click="wizard = 2"
                >
                  Continue
                </v-btn>
              </v-stepper-content>
              <v-stepper-content step="2">
                <v-card
                  color="lighten-1"
                >
                  <v-row>
                    <v-col>
                      <v-switch
                        v-model="filter_by_years_switch"
                        class="pl-2"
                        inset
                        :label="'Filter by Year? ' + formatBoolean (filter_by_years_switch)"
                      />
                    </v-col>
                    <template v-if="filter_by_years_switch">
                      <v-col>
                        <v-combobox
                          v-model="selected_years"
                          :items="all_years"
                          label="Select the years you want to filter by"
                          multiple
                        />
                      </v-col>
                    </template>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-switch
                        v-model="filter_by_makes_switch"
                        class="pl-2"
                        inset
                        :label="'Filter by Make? ' + formatBoolean (filter_by_makes_switch)"
                      />
                    </v-col>
                    <template v-if="filter_by_makes_switch">
                      <v-col>
                        <v-combobox
                          v-model="selected_makes"
                          :items="all_makes"
                          label="Select the makes you want to filter by"
                          multiple
                        />
                      </v-col>
                    </template>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-switch
                        v-model="filter_by_models_switch"
                        class="pl-2"
                        inset
                        :label="'Filter by Model? ' + formatBoolean (filter_by_models_switch)"
                      />
                    </v-col>
                    <template v-if="filter_by_models_switch">
                      <v-col>
                        <v-combobox
                          v-model="selected_models"
                          :items="all_models"
                          label="Select the models you want to filter by"
                          multiple
                        />
                      </v-col>
                    </template>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-switch
                        v-model="filter_by_states_switch"
                        class="pl-2"
                        inset
                        :label="'Filter by State? ' + formatBoolean (filter_by_states_switch)"
                      />
                    </v-col>
                    <template v-if="filter_by_states_switch">
                      <v-col>
                        <v-combobox
                          v-model="selected_states"
                          :items="all_states"
                          label="Select the states you want to filter by"
                          multiple
                        />
                      </v-col>
                    </template>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-switch
                        v-model="filter_by_last_serviced_switch"
                        class="pl-2"
                        inset
                        :label="'Filter by Last Serviced Date? ' + formatBoolean (filter_by_last_serviced_switch)"
                      />
                    </v-col>
                    <template v-if="filter_by_last_serviced_switch">
                      <v-col>
                        <v-menu
                          v-model="start_last_serviced_date_menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="formattedLastServiceStartDate"
                              label="Start Date"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-on="on"
                            />
                          </template>
                          <v-date-picker
                            v-model="start_last_serviced_date"
                            @input="start_last_serviced_date_menu = false"
                          />
                        </v-menu>
                      </v-col>
                      <v-col>
                        <v-menu
                          v-model="end_last_serviced_date_menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="formattedLastServiceEndDate"
                              label="End Date"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-on="on"
                            />
                          </template>
                          <v-date-picker
                            v-model="end_last_serviced_date"
                            @input="end_last_serviced_date_menu = false"
                          />
                        </v-menu>
                      </v-col>
                    </template>
                  </v-row>  
                  <v-row>
                    <v-col>
                      <v-switch
                        v-model="filter_by_op_codes_switch"
                        class="pl-2"
                        inset
                        :label="'Filter by Op Codes? ' + formatBoolean (filter_by_op_codes_switch)"
                      />
                    </v-col>
                    <template v-if="filter_by_op_codes_switch">
                      <v-col>
                        <v-autocomplete 
                                  v-model="opCodes" 
                                  :items="entries" 
                                  :loading="isLoading" 
                                  :search-input.sync="opCodeSearch"
                                  hide-no-data 
                                  hide-selected 
                                  item-text="Description" 
                                  item-value="Op Codes" 
                                  label="Op Codes" 
                                  placeholder="Start typing to search" 
                                  prepend-icon="mdi-database-search"
                                  return-object
                                  chips
                                  deletable-chips
                                  multiple
                                  >
                        </v-autocomplete>
                      </v-col>
                    </template>
                  </v-row>                               
                </v-card>
                <v-btn
                  color="primary"
                  @click="wizard = 3"
                >
                  Continue
                </v-btn>
                <v-btn
                  text
                  @click="wizard = 1"
                >
                  Back
                </v-btn>
              </v-stepper-content>
              <v-stepper-content step="3">
                <v-card
                  color="lighten-1"
                >
                  <v-row>
                    <v-col>
                      <v-combobox
                        v-model="selectedHeaders"
                        :items="allHeaders"
                        label="Select the columns you want to appear"
                        multiple
                      />
                    </v-col>
                  </v-row>
                </v-card>
                <v-btn
                  color="primary"
                  @click="wizard = 4"
                >
                  Continue
                </v-btn>
                <v-btn
                  text
                  @click="wizard = 2"
                >
                  Back
                </v-btn>
              </v-stepper-content>
              <v-stepper-content step="4">
                <v-card
                  color="lighten-1"
                >
                  <v-row dense>
                    <v-col>
                      <v-select
                        v-model="campaignId"
                        :items="campaigns"
                        label="Campaigns"
                        outlined
                        item-text="name"
                        item-value="id"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-text-field
                        v-model="fileDescription"
                        label="File Description"
                      />
                    </v-col>
                  </v-row>
                </v-card>
                <v-btn
                  color="primary"
                  @click="loadService"
                >
                  Finish
                </v-btn>
                <v-btn
                  text
                  @click="wizard = 3"
                >
                  Back
                </v-btn>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </template>
      </v-col>
    </v-row>
    <template v-if="items.length > 0">
      <v-row>
        <v-col>
          <h2>
            {{ items.length }} Records
          </h2>
        </v-col>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-col class="text-right">
              <download-excel
                :data="parsedItems"
                type="csv"
                :name="fileName"
              >
                <v-icon
                  large
                  color="green darken-2"
                  v-on="on"
                >
                  mdi-microsoft-excel
                </v-icon>
              </download-excel>
            </v-col>
          </template>
          <span>Download</span>
        </v-tooltip>
      </v-row>
    </template>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
          </v-card-title>
          <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="selectedHeaders"
            :items="items"
            :search="search"
            :items-per-page="50"
            :sort-by="['make']"
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-card>
                  <v-card-title
                    class="headline"
                  >
                    More info about {{ item.year }}
                  </v-card-title>
                </v-card>
              </td>
            </template>
            <template #item.ro_amount="{value}">
              {{ formatPrice(value) }}
            </template>
            <template #item.total_cost="{value}">
              {{ formatPrice(value) }}
            </template>
            <template #item.customer_total_sale="{value}">
              {{ formatPrice(value) }}
            </template>
            <template #item.customer_total_cost="{value}">
              {{ formatPrice(value) }}
            </template>
            <template #item.warranty_total_sale="{value}">
              {{ formatPrice(value) }}
            </template>
            <template #item.warranty_total_cost="{value}">
              {{ formatPrice(value) }}
            </template>
            <template #item.close_date="{value}">
              {{ formatDate(value) }}
            </template>
            <template #item.phone="{value}">
              {{ formatPhoneNumber(value) }}
            </template>
            <template #item.mileage="{value}">
              {{ formatNumber(value) }}
            </template>
            <template #item.last_serviced_at="{value}">
              {{ formatDate(value) }}
            </template>
            <template #item.prior_serviced_at="{value}">
              {{ formatDate(value) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="too_many_records"
      persistent
      max-width="500"
    >
      <v-card class="pl-2 pr-2">
        <v-card-title class="headline">
          Large Number of Records
        </v-card-title>
        <v-row>
          <v-col>
            <v-alert
              type="success"
              icon="mdi-email"
            >
              Only a sample of record is being shown because there's too many records to retrieve at once. <br><br>
              <strong>Would you like the complete report to be emailed to you?</strong>
            </v-alert>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="emailReport"
          >
            Yes
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="too_many_records = false"
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          {{ loadingMessage }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="tooManyRecords"
      persistent
      max-width="500"
    >
      <v-card class="pl-2 pr-2">
        <v-card-title class="headline">
          Large Number of Records
        </v-card-title>
        <v-row>
          <v-col>
            <v-alert
              type="success"
              icon="mdi-email"
            >
              Only a sample of record is being shown because there's too many records to retrieve at once. <br><br>
              <strong>Would you like the complete report to be emailed to you?</strong>
            </v-alert>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="emailReport"
          >
            Yes
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="tooManyRecords = false"
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showErrorDialog"
      persistent
      max-width="600"
      @keydown.esc="showErrorDialog = false"
    >
      <error-message
        :error="error"
        @closeErrorDialog="closeErrorDialog"
      />
    </v-dialog>    
  </v-container>
</template>
<script>
  import NetworkService from '@/services/NetworkService.js'
  import ErrorMessage from '@/components/ErrorMessage.vue'
  import DmsReportingService from '@/services/DmsReportingService.js'
  import MailCampaignService from '@/services/MailCampaignService'
  import { mapGetters } from 'vuex'
  import { pick } from '@/mixins/exportMixin.js'
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  import { serviceMixin } from '@/mixins/serviceMixin.js'

  export default {
    name: 'DmsServiceList',
    components: {
      ErrorMessage,
    },
    mixins: [formatterMixin, serviceMixin],
    data () {
      return {
        showErrorDialog: false,
        reportType: 'service_query',
        loadingMessage: 'Loading',
        cacheKey: null,
        start_date: new Date(new Date().setDate(new Date().getDate() - 7))
          .toISOString()
          .substring(0, 10),
        end_date: new Date().toISOString().substring(0, 10),
        start_last_serviced_date: new Date(new Date().setDate(new Date().getDate() - 7))
          .toISOString()
          .substring(0, 10),
        end_last_serviced_date: new Date().toISOString().substring(0, 10),
        campaign_date: new Date(new Date().setDate(new Date().getDate()))
          .toISOString()
          .substring(0, 10),
        start_date_menu: false,
        end_date_menu: false,
        start_last_serviced_date_menu: false,
        end_last_serviced_date_menu: false,
        campaign_date_menu: false,
        campaign_name: '',
        fileDescription: 'Service Query',
        wizard: 1,
        items: [],
        search: '',
        loading: false,
        error: '',
        expanded: [],
        singleExpand: false,
        too_many_records: false,
        all_makes: [],
        selected_makes: [],
        all_models: [],
        selected_models: [],
        all_years: [],
        selected_years: [],
        all_states: [],
        selected_states: ['TX'],
        filter_by_makes_switch: false,
        filter_by_models_switch: false,
        filter_by_years_switch: false,
        filter_by_states_switch: true,
        filter_by_last_serviced_switch: false,
        filter_by_op_codes_switch: false,
        save_as_campaign_switch: false,
        rules: {
          required: value => !!value || 'Required.',
        },
        campaignId: null,
        campaigns: [],
        tooManyRecords: false,
        allHeaders: [
          { text: 'RO #', value: 'ro_number' },
          { text: 'RO Amount', value: 'ro_amount', align: 'center' },
          { text: 'Closed Date', value: 'close_date' },
          { text: 'Customer Number', value: 'customer_number' },
          { text: 'First Name', value: 'first_name', align: 'center' },
          { text: 'Last Name', value: 'last_name', align: 'center' },
          { text: 'Address 1', value: 'address_1', align: 'center' },
          { text: 'Address 2', value: 'address_2', align: 'center' },
          { text: 'City', value: 'city', align: 'center' },
          { text: 'State', value: 'state', align: 'center' },
          { text: 'Zip', value: 'zip', align: 'center' },
          { text: 'Phone', value: 'phone', align: 'center' },
          { text: 'Email', value: 'email', align: 'center' },
          { text: 'Year', value: 'year', align: 'center' },
          { text: 'Make', value: 'make', align: 'center' },
          { text: 'Model', value: 'model', align: 'center' },
          { text: 'Trim', value: 'trim', align: 'center' },
          { text: 'VIN', value: 'vin', align: 'center' },
          { text: 'Total Cost', value: 'total_cost', align: 'center' },
          { text: 'Customer Sale', value: 'customer_total_sale', align: 'center' },
          { text: 'Customer Cost', value: 'customer_total_cost', align: 'center' },
          { text: 'Warranty Sale', value: 'warranty_total_sale', align: 'center' },
          { text: 'Warranty Cost', value: 'warranty_total_cost', align: 'center' },
          { text: 'Last Service Date', value: 'last_serviced_at', align: 'center' },
          { text: 'Prior Service Date', value: 'prior_serviced_at', align: 'center' },
          { text: 'Address Validated?', value: 'validated', align: 'center' },
        ],
        selectedHeaders: [
          { text: 'RO #', value: 'ro_number' },
          { text: 'Customer Number', value: 'customer_number' },
          { text: 'First Name', value: 'first_name', align: 'center' },
          { text: 'Last Name', value: 'last_name', align: 'center' },
          { text: 'Address 1', value: 'address_1', align: 'center' },
          { text: 'Address 2', value: 'address_2', align: 'center' },
          { text: 'City', value: 'city', align: 'center' },
          { text: 'State', value: 'state', align: 'center' },
          { text: 'Zip', value: 'zip', align: 'center' },
          { text: 'Phone', value: 'phone', align: 'center' },
          { text: 'Email', value: 'email', align: 'center' },
          { text: 'Year', value: 'year', align: 'center' },
          { text: 'Make', value: 'make', align: 'center' },
          { text: 'Model', value: 'model', align: 'center' },
          { text: 'VIN', value: 'vin', align: 'center' },
          { text: 'Closed Date', value: 'close_date' },
        ],
        opCodeResults: [],
        entries: [],
        isLoading: false,
        opCodes: [],
        opCodeSearch: null        
      }
    },
    computed: {
      formattedCampaignDate () {
        return this.formatDate(this.campaign_date)
      },
      formattedStartDate () {
        return this.formatDate(this.start_date)
      },
      formattedEndDate () {
        return this.formatDate(this.end_date)
      },
      formattedLastServiceStartDate () {
        return this.formatDate(this.start_last_serviced_date)
      },
      formattedLastServiceEndDate () {
        return this.formatDate(this.end_last_serviced_date)
      },
      parsedItems () {
        const headers = this.selectedHeaders.map(h => h.value)
        if (this.items) {
          return this.items.map(i => pick(i, headers))
        }
        return []
      },
      fileName () {
        return `${this.accountName
          .toLowerCase()
          .replace(' ', '_')}_dms_service.xls`
      },
      ...mapGetters(['accountId', 'accountName', 'reportData']),
    },
    watch: {
      accountId () {
        this.getMakes()
        this.getModels()
        this.getYears()
        this.getStates()
        this.loadCampaigns()
      },
      reportData () {
        this.loadingMessage = 'Rendering Report'
        if (this.reportData.reportType === this.reportType) {
          this.cacheKey = this.reportData.cacheKey
          this.items = []
          this.loading = true
          DmsReportingService.retrieveReport(this.reportData.cacheKey, this.campaignId, 'Service Query', this.selectedHeaders.map(h => h.value), this.reportType, this.reportData.fileName)
            .then(response => {
              if (response.data.report) {
                this.items = response.data.report.items
                this.tooManyRecords = response.data.too_many_records
              } else {
                this.items = []
              }
              this.loading = false
            })
            .catch(error => {
              this.loading = false
              if (!error.response) {
                this.error = 'Network Error. Check your internet connection.'
              } else {
                var errorMessage = error.response.data.error
                if (errorMessage) {
                  this.error = errorMessage
                } else { this.error = 'The server returned an error. Please contact Dealer Insights support.' }
              }
            })
        }
      },
      opCodeSearch (val) {
      // Items have already been requested
      // if (this.loading) return

      this.loading = true

      var data = {term: this.opCodeSearch}
      DmsReportingService.searchOpCodes(data)
        .then(response => {
          this.entries = this.entries.concat(response.data)
          this.entries  = this.entries.filter((v, i, a) => a.indexOf(v) === i);
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          this.error = NetworkService.getErrorMessage(error)
          this.showErrorDialog = true
        })
        .finally(() => (this.loading = false))
      }      
    },
    created () {
      this.getMakes()
      this.getModels()
      this.getYears()
      this.getStates()
      this.loadCampaigns()
    },
    methods: {
      closeErrorDialog: function () {
        this.showErrorDialog = false
      },
      loadService: function () {
        this.items = []
        this.loading = true
        var data = {
          startDate: this.start_date,
          endDate: this.end_date,
        }
        data.headerText = this.selectedHeaders.map(h => encodeURIComponent(h.text))
        data.headerValues = this.selectedHeaders.map(h => encodeURIComponent(h.value))

        if (this.filter_by_makes_switch) {
          data.makes = this.selected_makes.join(',')
        }

        if (this.filter_by_models_switch) {
          data.models = this.selected_models.join(',')
        }

        if (this.filter_by_years_switch) {
          data.years = this.selected_years.join(',')
        }

        if (this.filter_by_states_switch) {
          data.states = this.selected_states.join(',')
        }

        if (this.filter_by_op_codes_switch) {
          data.opCodes = this.opCodes.join(',')
        }

        if (this.save_as_campaign_switch) {
          data.campaignName = this.campaign_name
          data.campaignDate = this.campaign_date
        }

        if (this.filter_by_last_serviced_switch) {
          data.startLastServicedDate = this.start_last_serviced_date
          data.endLastServiceDate = this.end_last_serviced_date
        }
        data.campaignId = this.campaignId
        data.fileDescription = this.fileDescription

        DmsReportingService.serviceQuery(data)
          .then(response => {
            this.loadingMessage = 'Running Report'
            if (response.data.items) {
              this.items = response.data.items
            } else {
              this.items = []
            }
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      loadCampaigns: function () {
        this.campaignId = null
        this.campaigns = []
        this.loading = true
        MailCampaignService.getMailCampaigns()
          .then(response => {
            this.campaigns = response.data
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      emailReport: function () {
        this.loading = true
        var data = {
          cacheKey: this.reportData.fileName,
          headerValues: this.selectedHeaders.map(h => encodeURIComponent(h.value)),
        }

        DmsReportingService.emailReport(data)
          .then(response => {
            this.tooManyRecords = false
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
    },
  }
</script>
